<template>
  <div v-if="showData">
    <!-- 項目 -->
    <b-card
      no-body
      class="mx-2 mx-sm-5 mt-5"
    >
      <!-- user suggestion  -->
      <div class="d-flex justify-content-start align-items-start p-75">
        <!-- <b-avatar :src="data.image" class="mr-50" size="60" /> -->
        <b-img
          :src="`https://upload.cc/i1/2021/02/13/xrhwRD.jpg`"
          rounded
          fluid
          class="mr-50 width-100 height-100"
          style="object-fit: cover;"
        />
        <div class="user-page-info">
          <h4 class="font-weight-bolder mb-0">
            {{ showData.name }}
          </h4>
          <small class="text-muted">{{ showData.name }}</small>
        </div>

        <div
          style="position: absolute; right: 10px; bottom: 5px;"
          class="btn-icon ml-auto"
        >
          <!-- 180 min/$1000起 -->
          <small class="text-muted">{{ showData.interval }} min</small><span
            v-if="showData.price > 0"
            class="h5 font-weight-bolder text-danger"
          > / ${{ showData.price }}起</span>
        </div>

      </div>
      <!--/ user suggestion  -->
    </b-card>

    <!-- 預約時間 -->
    <b-tabs class="mx-2 mx-sm-5">
      <b-tab title="預約時間＆日期">

        <b-card-text>

          <b-col
            cols="12"
            md="12"
          >
            <label
              class="h5 mt-2"
              for="select-date"
            >STEP1 確認預約項目</label>
            <b-form-input
              id="plainTextInput"
              v-model="reserveOrder.name"
              disabled
            />
          </b-col>
          <hr>

          <b-col
            cols="12"
            md="12"
          >
            <label
              class="h5 mt-2"
              for="select-date"
            >STEP2 選擇日期</label>
            <b-form-datepicker
              id="select-date"
              v-model="reserveOrder.date"
              v-bind="zh_TW_date"
              class="mt-50"
            />
          </b-col>
          <hr>

          <b-col
            cols="12"
            md="12"
          >
            <label
              class="h5 mt-2"
              for="select-date"
            >STEP3 選擇時間</label>
            <div
              v-if="!reserveOrder.time"
              class="d-flex justify-content-start flex-wrap"
            >
              <div
                v-for="time in showData.times_list"
                :key="time"
                class="text-center colors-container bg-gradient-danger rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-50 shadow"
                @click="selectTime(time)"
              >
                <span>{{ time }}</span>
              </div>
            </div>

            <b-input-group v-if="reserveOrder.time">
              <b-form-timepicker
                id="select-date"
                v-model="reserveOrder.time"
                v-bind="zh_TW_time"
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="$delete(reserveOrder, 'time')"
                >
                  重新選
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <hr>

          <b-col
            cols="12"
            md="12"
          >
            <label
              class="h5 mt-2"
              for="select-date"
            >STEP4 輸入姓名</label>
            <b-form-input
              id="select-date"
              v-model="reserveOrder.displayName"
              class="mt-50"
            />
          </b-col>
          <hr>

          <b-col
            cols="12"
            md="12"
          >
            <label
              class="h5 mt-2"
              for="select-date"
            >STEP5 輸入電話</label>
            <b-form-input
              id="select-date"
              v-model="reserveOrder.phone"
              class="mt-50"
            />
          </b-col>
          <hr>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              class="mt-2"
              variant="dark"
              @click="sendReserve"
            >
              確定
            </b-button>
          </b-col>

        </b-card-text>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BCard, BImg, BTabs, BTab, BCardText, BCol, BFormInput, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import store from '@/store'

// components
// import ClientLiffView from '@/views/frontend/client/ClientLiffView.vue'

//
import dataStoreModule from '../reserveStoreModule'

export default {
  components: {
    // ClientLiffView,
    BCard,
    BImg,
    BTabs,
    BTab,
    BCardText,
    BCol,
    BFormInput,
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      zh_TW_date: {
        labelPrevYear: '上一年',
        labelPrevMonth: '上個月',
        labelCurrentMonth: '當前月份',
        labelNextMonth: '下個月',
        labelNextYear: '明年',
        labelToday: '今天',
        labelSelected: '選定日期',
        labelNoDateSelected: '未選擇日期',
        labelCalendar: '日曆',
        labelNav: '日曆導航',
        labelHelp: '使用游標鍵點選日期',
      },
      zh_TW_time: {
        labelHours: '小時',
        labelMinutes: '分鐘',
        labelSeconds: '秒',
        labelAmpm: '上午下午',
        labelAm: '上午',
        labelPm: '下午',
        labelIncrement: '增量',
        labelDecrement: '減量',
        labelSelected: '選定時間',
        labelNoTimeSelected: '沒有選擇時間',
        labelCloseButton: '關',
      },
    }
  },
  setup() {
    // Data func
    const showData = ref({})
    const reserveOrder = ref({})

    const APP_STORE_MODULE_NAME = 'app-client-reserve'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // LINE LIFF 技術
    // const activeLiff = computed(() => store.state['app-line-liff'].profileInfo)

    // watch(() => activeLiff.value, val => {
    //   root.$set(reserveOrder.value, 'displayName', val.displayName)
    //   root.$set(reserveOrder.value, 'member_id', val.id)
    // })

    // Use toast
    const toast = useToast()

    const fetchOne = () => {
      showData.value = null
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchReserve`, router.currentRoute.params.id)
        .then(response => {
          showData.value = response.data
          reserveOrder.value.reserve_id = showData.value.id
          reserveOrder.value.name = showData.value.name
        })
        .catch(error => {
          if (error.response.status === 404) {
            showData.value = undefined
          }
        })
    }

    fetchOne()

    const selectTime = time => {
      router.app.$set(reserveOrder.value, 'time', time)
    }

    const sendReserve = () => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/addReserveOrder`, reserveOrder.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '預約成功',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      // .catch(() => {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Error fetching users list',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      // })
    }

    return {
      // ref
      showData,
      reserveOrder,

      selectTime,
      sendReserve,
    }
  },
}
</script>

<style lang="scss" >
.card-img-top {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: 100% 10%;
  opacity: 0.6;
}
</style>
